.AddAnnotationWindow {
  position: absolute;
  z-index: 10;
  width: 300px;
  height: auto;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;

  &-Header {
    min-height: 36px;
    height: auto;
    padding: 8px 16px;
    font-family: "CarosSoft";
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    background-color: #F9FAFB;
    border-bottom: 1px solid #EAECF0;
  }

  &-BackIcon {
    margin-right: 15px;
    cursor: pointer;
  }

  &-Title {
    padding: 8px 16px;
    height: 34px;
    font-family: "CarosSoftLight";
    font-size: 12px;
    line-height: 18px;
    color: #344054;
  }

  &-Label {
    display: flex;
    padding: 8px 16px;
    height: 44px;
    cursor: pointer;

    &:hover {
      background-color: #FCFCFD;
    }

    &__Active {
      background-color: #FCFCFD;
    }
  }

  &-Footer {
    padding: 9px 10px;
    min-height: 46px;
    height: auto;
    border-top: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
    font-family: "CarosSoft";
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }

  &-BtnBox {
    padding: 9px 10px;
    min-height: 46px;
    height: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &-Input {
    height: 28px;
  }

  &-InputLabel {
    margin-right: 5px;
  }

  &-AnnotationType {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 36px;
    font-family: "CarosSoft";
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    cursor: pointer;

    &:hover {
      background-color: #FCFCFD;
    }

    &__Active {
      background-color: #FCFCFD;
    }
  }

  &-AnnotationTypeIcon {
    margin-right: 10px;
    width: 10px;
    transform: translateY(1px);
  }
}