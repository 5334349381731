.ViewerControls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 150px;
  height: 42px;
  background: #FFFFFF;
  box-shadow: 0px 6px 40px rgba(83, 80, 102, 0.12);
  border-radius: 40px;

  &-Input {
    width: 40px;
    margin-right: 5px;
    text-align: center;
    height: 30px !important;
  }

  &-Icon {
    cursor: pointer;

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }
  }

  &-Label {
    font-family: "CarosSoftBold";
    font-size: 12px;
    line-height: 12px;
    color: #535066;
    margin-right: 5px;
  }
}