.DocumentPreview {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  width: 248px;
  height: 225px;
  background: #f9fafb;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    & .DocumentPreview-DeleteIcon {
      opacity: 1;
      transition: all 0.2s ease;
    }
    & .DocumentPreview-ReloadIcon {
      opacity: 1;
      transition: all 0.2s ease;
    }
  }

  &-Wrapper {
    height: 225px;
  }

  &-Document {
    margin: 0 auto 8px auto;
    width: 94px;
    height: 134px;
    max-width: 100%;
    text-align: center;
    border: 1px solid #eaecf0;
  }

  &-FailedToLoadPDFLabel {
    font-size: 12px;
    line-height: 18px;
    color: #475467;
    padding: 5px;
  }

  &-FileName {
    font-family: "CarosSoftLight";
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #475467;
    margin-bottom: 10px;
  }

  &-Status {
    height: 22px;
    line-height: 22px;
    padding: 2px 8px;
    background: #ecfdf3;
    border-radius: 16px;
    font-family: "Inter";
    font-style: normal;
    font-family: "CarosSoftBold";
    font-size: 12px;
    color: #027a48;

    &__EnCourses {
      background: #fff3cd !important;
      color: orange !important;
    }

    &__Does {
      background: lightgreen !important;
    }
  }

  &__Active {
    background: #f2f0ff;
    border: 2px solid #7057ff;

    & .DocumentPreview-FileName {
      color: #7057ff;
      font-family: "CarosSoftBold";
    }
  }

  &-DeleteIcon {
    opacity: 0;
    transition: all 0.2s ease;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    width: 18px;
    height: 20px;
    cursor: pointer;
  }

  &-ReloadIcon {
    opacity: 0;
    transition: all 0.2s ease;
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 20px;
    width: 18px;
    height: 20px;
    cursor: pointer;
  }

  &-Page {
    max-width: 95px;
    height: auto;
  }
}
