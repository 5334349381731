.Header {
  padding: 15px 20px 10px 20px;
  border-bottom: 1px solid #f1f4fa;
  line-height: 36px;

  &-BackBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    margin-right: 16px;
  }

  &-ClickableTrainBtn {
    background-color: lightgreen;

    &:hover {
      color: #344054 !important;
    }
  }

  &-AnalyseBtn {
    margin-left: 10px;

    &:hover {
      color: #344054 !important;
    }
  }

  &-Title {
    font-size: 24px;
    text-align: right !important;
  }

  &-Modal {
    width: fit-content !important;

    &-CloseButton {
      margin-top: 10px;
      width: 100% !important;
    }
  }
}

.TestHeader {
  padding: 15px 20px 10px 20px;
  color: rgb(232 240 253);
  background-color: rgb(52 65 84);
  border-bottom: 1px solid rgb(52 65 84);
  line-height: 36px;
  &-BackBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 16px;
  }
}
