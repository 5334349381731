.ImportDocument {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 12px;
  width: 248px;
  height: 144px;
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 16px;

  &-Icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

  &-Title {
    font-family: "CarosSoftLight";
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    margin-bottom: 4px;

    &>span {
      font-family: "Inter";
      color: #7057FF;
      font-family: "CarosSoftBold";
    }
  }

  &-Subtitle {
    font-family: "CarosSoftLight";
    font-size: 12px;
    line-height: 18px;
    color: #475467;
  }
}