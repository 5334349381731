.NoDocuments {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 352px;
  height: 132px;
  text-align: center;

  &-Icon {
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
  }

  &-Title {
    font-family: "CarosSoft";
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    margin-bottom: 4px;
  }

  &-Subtitle {
    font-family: "CarosSoftLight";
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
}