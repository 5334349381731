.Home {

  &-ModalWrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(83, 80, 102, 0.3);
  }
}

.Modal {
  width: 660px;
  min-height: 220px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;

  &-Title {
    font-family: "CarosSoftBold";
    font-size: 16px;
    line-height: 20px;
    color: #535066;
    margin-bottom: 12px;
  }

  &-Content {
    font-family: "CarosSoftBold";
    font-size: 14px;
    line-height: 17px;
    color: #A7A4B7;
    margin-bottom: 12px;
  }

  &-Label {
    font-family: "CarosSoft";
    font-size: 11px;
    line-height: 14px;
    color: #535066;
  }

  &-Input {
    margin: 8px 0 12px 0;
  }

  &-Btns {
    display: flex;
    justify-content: flex-end;
  }

  &-Btn {
    &:first-child {
      margin-right: 10px;
    }
  }
}