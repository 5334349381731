.Annotation {
  padding: 16px;
  height: 116px;
  border-top: 1px solid #eaecf0;

  &:last-child {
    border-bottom: 1px solid #eaecf0;
  }

  &-Header {
    margin-bottom: 12px;
  }

  &-Type {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px 4px 10px;
    min-width: 70px;
    width: 100%;
    height: 28px;
    background: #ecfdf3;
    border-radius: 16px;
    font-family: "CarosSoft";
    font-size: 14px;
    line-height: 20px;
    color: #027a48;
    overflow: hidden;
  }

  &-IconType {
    margin-right: 6px;
  }

  &-Persent {
    font-family: "CarosSoftLight";
    font-size: 15px;
    line-height: 15px;
    color: #535066;
    margin-right: 10px;
  }

  &-DeleteIcon {
    width: 18px;
    height: 20px;
    cursor: pointer;
  }

  &-Name {
    font-family: "CarosSoft";
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
}
